const genEnums = keys =>
	keys.map((key, index) => ({
		name: key,
		code: index + 1,
	}))
export const status = genEnums([
	'待整备',
	'待维修',
	'维修中',
	'待出租',
	'待交车',
	'待退车',
	'出租中',
	'已报废',
])
export const maintenanceStatus = genEnums(['待确认', '维保拒绝', '维保中', '已完成'])
export const liability = genEnums(['全责', '主责', '次责', '同责', '无责'])
export default {
	status,
	maintenanceStatus,
}
