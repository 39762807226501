import request from '@/http'

// 车辆管理列表
export function vehicleList(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/paginate',
		method: 'post',
		data,
	})
}

// 新增车辆
export function createVehicle(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/create',
		method: 'post',
		data,
	})
}
// 编辑车辆
export function editVehicle(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/update',
		method: 'post',
		data,
	})
}

// 批量年检
export function batchCheck(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/batchAnnualInspect',
		method: 'post',
		data,
	})
}

// 批量修改维保专员
export function batchSpecialist(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/batchModifyMaintainer',
		method: 'post',
		data,
	})
}

// 通过车牌号集合获取到该车辆最新的保险记录信息
export function batchGetInsuranceRecord(data) {
	return request({
		url: `/suf4-asset-service/carInsurance/latest/record/by/carNumber?carNumbers=${data}`,
		method: 'get',
	})
}

// 批量续保
export function batchRenewal(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/batchRenewalInsurance',
		method: 'post',
		data,
	})
}

// 车辆详情
export function vehicleDetail(id) {
	return request({
		url: `/suf4-asset-service/pc/assetCarInfo/getById/${id}`,
		method: 'get',
	})
}

// 操作记录
export function operationRecords(data) {
	return request({
		url: `/suf4-system-service/changeLog/paginate`,
		method: 'post',
		data,
	})
}

// 延期记录
export function delayRecords(id) {
	return request({
		url: '/suf4-asset-service/order/info/contract/delay/list?orderId=' + id,
		method: 'GET',
	})
}

// 入库单列表
export function assetCarStockInList(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarStockIn/paginate',
		method: 'post',
		data,
	})
}

// 入库单详情
export function carStockInDetail(id) {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockIn/getById/${id}`,
		method: 'get',
	})
}
// 编辑入库单
export function editCarStockIn(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarStockIn/update',
		method: 'post',
		data,
	})
}

// 出库单列表
export function assetCarStockOutList(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarStockOut/paginate',
		method: 'post',
		data,
	})
}

// 新建出库单
export function createCarStockOut(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarStockOut/create',
		method: 'post',
		data,
	})
}
// 编辑出库单
export function editCarStockOut(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarStockOut/update',
		method: 'post',
		data,
	})
}
// 出库单详情
export function carStockOutDetail(id) {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockOut/getById/${id}`,
		method: 'get',
	})
}
// 新建入库单
export function createCarStockIn(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarStockIn/create',
		method: 'post',
		data,
	})
}
// 车辆信息下拉选择
export function carSelectList(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/carSelectList',
		method: 'post',
		data,
	})
}

// 维保管理列表
export function maintenanceList(data) {
	return request({
		url: '/suf4-asset-service/assetMaintenanceInfo/paginate',
		method: 'post',
		data,
	})
}
// 维保详情
export function maintenanceDetail(id) {
	return request({
		url: `/suf4-asset-service/assetMaintenanceInfo/getById/${id}`,
		method: 'get',
	})
}

// 新增/编辑维保单
export function assetMaintenance(data) {
	return request({
		url: '/suf4-asset-service/assetMaintenanceInfo/saveOrUpdate',
		method: 'post',
		data,
	})
}
// 完成维修
export function maintainCompleted(data) {
	return request({
		url: '/suf4-asset-service/assetMaintenanceInfo/maintenanceCompleted',
		method: 'post',
		data,
	})
}
// 新增/编辑事故
export function assetIncidental(data) {
	return request({
		url: '/suf4-asset-service/assetAccidentInfo/saveOrUpdate',
		method: 'post',
		data,
	})
}

// 事故详情
export function incidentalDetail(id) {
	return request({
		url: `/suf4-asset-service/assetAccidentInfo/getById/${id}`,
		method: 'get',
	})
}

// 事故管理列表
export function incidentalList(data) {
	return request({
		url: '/suf4-asset-service/assetAccidentInfo/paginate',
		method: 'post',
		data,
	})
}

// 违章管理列表
export function violationList(data) {
	return request({
		url: '/suf4-asset-service/violate/regulation/page',
		method: 'post',
		data,
	})
}

// 保险公司下拉筛选
export function insuranceCompanySelect(params) {
	return request({
		url: '/suf4-user-service/insuranceCompany/select',
		method: 'get',
		params,
	})
}

// 所属公司下拉筛选
export function companySelect(isPermission, params) {
	return request({
		url: `/suf4-user-service/company/select${isPermission ? '?isPermission=true' : ''}`,
		method: 'get',
		params,
	})
}
// 平台渠道下拉筛选
export function channelSelect(params) {
	return request({
		url: '/suf4-user-service/platformChannel/select',
		method: 'get',
		params,
	})
}

// 库存地/维修厂下拉筛选(库存地20，维修厂10)
export function storageSelect(type, params) {
	return request({
		url: `/suf4-user-service/sysRepairStorage/select/${type}`,
		method: 'get',
		params,
	})
}
// 验车负责人下拉
export function checkUserList() {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockIn/createUserList`,
		method: 'get',
	})
}
// 交车负责人下拉
export function deliveryUserList() {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockOut/createUserList`,
		method: 'get',
	})
}

// 角色下拉筛选 维保专员：Mainten、司服人员：DriverService 销售：Sale
export function maintenanceSelect(roleCode, data = [], params) {
	return request({
		url: `/suf4-user-service/userInfo/listByRoleCode/${roleCode}`,
		method: 'post',
		data,
		params,
	})
}

// 车辆车型搜索筛选
export function searchModel(data) {
	return request({
		url: `/suf4-user-service/sysCarModel/searchModel`,
		method: 'post',
		data,
	})
}

// 维保项目树形
export function maintainTree() {
	return request({
		url: `/suf4-user-service/maintainingProject/tree`,
		method: 'get',
	})
}

// 导出维保单
export function exportMaintenance(data) {
	return request({
		url: `/suf4-asset-service/assetMaintenanceInfo/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
// 导出事故单
export function exportAccident(data) {
	return request({
		url: `/suf4-asset-service/assetAccidentInfo/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 导出违章记录
export function exportViolation(data) {
	return request({
		url: `/suf4-asset-service/violate/regulation/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 导出车辆
export function exportVehicle(data) {
	return request({
		url: `/suf4-asset-service/pc/assetCarInfo/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 导入车辆
export function importVehicle(data) {
	return request({
		url: `/suf4-asset-service/pc/assetCarInfo/import`,
		method: 'post',
		data,
	})
}

// 导出入库单
export function exportStockIn(data) {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockIn/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 导出出库单
export function exportStockOut(data) {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockOut/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 通过车辆ID获取到相关合同概要
export function getDetailByCar(params) {
	return request({
		url: `/suf4-asset-service/order/info/summary/by/car`,
		method: 'get',
		params,
	})
}

// 通过车辆ID获取违章记录
export function getViolationByCar(params) {
	return request({
		url: `/suf4-asset-service/violate/regulation/summary/by/car`,
		method: 'get',
		params,
	})
}
//根据车辆ID查询最新的出库单
export function getDeliveryByCar(id) {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockOut/getLatestByCarId/${id}`,
		method: 'get',
	})
}

//车型车系下拉筛选
export function getBrandSeriesSelect(type) {
	return request({
		url: `/suf4-user-service/sysCarBrandSeries/select/${type}`,
		method: 'post',
		data: {},
	})
}

//人员下拉筛选
export function getUserList(data) {
	return request({
		url: '/suf4-user-service/userInfo/paginate/select',
		method: 'post',
		data,
	})
}

//作废入库验车单
export function cancelCarStockIn(id) {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockIn/cancel/${id}`,
		method: 'post',
		data: {},
	})
}
//年检记录列表
export function annualInspectionList(data) {
	return request({
		url: `/suf4-asset-service/annualInspection/paginate`,
		method: 'post',
		data,
	})
}
//保险记录列表
export function carInsuranceList(data) {
	return request({
		url: `/suf4-asset-service/carInsurance/paginate`,
		method: 'post',
		data,
	})
}
//车辆状态统计
export function carStatusStat(data) {
	return request({
		url: `/suf4-asset-service/pc/assetCarInfo/statusStat`,
		method: 'post',
		data,
	})
}
//违章汇总列表
export function untreatedSummary(data) {
	return request({
		url: `/suf4-asset-service/violate/regulation/untreatedSummaryByCarNumber`,
		method: 'post',
		data,
	})
}
//删除保险记录
export function deleteInsurance(id) {
	return request({
		url: `/suf4-asset-service/carInsurance/removeById/${id}`,
		method: 'get',
	})
}
//删除年检记录
export function deleteAnnualInspection(id) {
	return request({
		url: `/suf4-asset-service/annualInspection/removeById/${id}`,
		method: 'get',
	})
}
//导出年检记录
export function exportAnnualInspection(data) {
	return request({
		url: `/suf4-asset-service/annualInspection/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
//导出保险记录
export function exportCarInsurance(data) {
	return request({
		url: `/suf4-asset-service/carInsurance/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
//导出违章汇总
export function exportUntreatedSummary(data) {
	return request({
		url: `/suf4-asset-service/violate/regulation/exportUntreatedSummary`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
//导出超时维修预警
export function exportOvertimeRepairs(data) {
	return request({
		url: `/suf4-risk-service/overtimeRepairs/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
