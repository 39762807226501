export const form = [
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		required: true,
		component: 'el-select-v2',
		custom: true,
		attrs: { placeholder: '请选择车牌号', options: [], filterable: true },
	},
	{
		prop: 'createUserName',
		label: '验车人',
		// required: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'carModelName',
		label: '车辆车型',
		// required: true,
		custom: true,
	},
	{
		prop: 'carNum',
		label: '自编号',
		// required: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'carColor',
		label: '车辆颜色',
		// required: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		// required: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'deliveryTime',
		label: '上次出库时间',
		// required: true,
		custom: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
]
export const input = [
	{
		prop: 'carMileage',
		label: '车辆里程(KM)',
		required: true,
		component: 'el-input',
		attrs: { maxLength: 7, placeholder: '请输入车辆里程' },
	},
	{
		prop: 'carStorageId',
		label: '库存地',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择库存地' },
	},
	{
		prop: 'inspectResult',
		label: '验车结果',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择验车结果' },
		options: [
			{ label: '无需维修', value: 0 },
			{ label: '需要维修', value: 1 },
		],
	},
	{
		prop: 'maintainReason',
		label: '维修原因',
		component: 'el-select',
		attrs: { placeholder: '请选择维修原因', clearable: true },
		options: [
			{ label: '维修厂', value: 0 },
			{ label: '司机', value: 1 },
			{ label: '自身', value: 2 },
		],
	},
]
