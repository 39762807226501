<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<yi-form
				:model="model"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			>
				<template #licensePlateNum>
					<el-select-v2
						placeholder="请选择车牌号"
						:options="licensePlateOptions"
						filterable
						size="default"
						:disabled="carNumDisabled"
						v-model="model.licensePlateNum"
						@change="onNumChange"
						style="width: 190px"
					>
						<template #default="{ item }">
							<div class="custom-option">
								<span>{{ item.label }}</span>
								<span>{{ item.statusName }}</span>
							</div>
						</template>
					</el-select-v2>
				</template>
				<template #carModelName>
					<el-input style="width: 500px" size="default" disabled v-model="model.carModelName" />
				</template>
				<template #deliveryTime>
					<div style="width: 500px">
						<el-input style="width: 190px" size="default" disabled v-model="model.deliveryTime" />
						<span
							v-if="model.deliveryId"
							class="cursor"
							@click="onDelivery"
							style="color: #409eff; margin-left: 20px; font-size: 14px; font-weight: 500"
						>
							查看交车单
						</span>
					</div>
				</template>
			</yi-form>
			<yi-form
				:model="model"
				:rules="rules"
				ref="inputRef"
				label-width="120px"
				size="small"
				:form-list="inputForm"
			/>
			<el-form ref="resultRef" :model="formData" style="width: 600px; margin-left: 20px">
				<el-table border style="margin: 20px 0" :data="formData.resultList">
					<el-table-column label="处理结果">
						<template #default="scope">
							<el-form-item
								:prop="`resultList.${scope.$index}.dealResult`"
								:rules="rules.dealResult"
							>
								<el-input
									maxLength="50"
									:disabled="disabled"
									v-model="scope.row.dealResult"
									style="width: 100%; margin-top: 20px"
								></el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column :label="tableLabel">
						<template #default="scope">
							<el-form-item
								:prop="`resultList.${scope.$index}.damageAmount`"
								:rules="rules.damageAmount"
							>
								<el-input
									:disabled="disabled"
									v-model="scope.row.damageAmount"
									style="width: 100%; margin-top: 20px"
									maxLength="7"
								></el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="date" label="操作" v-if="!disabled" width="100px">
						<template #default="scope">
							<div class="button-list">
								<span
									style="color: red"
									class="cursor"
									v-if="resultList.length > 1"
									@click="onDelete(scope)"
									type="danger"
								>
									删除
								</span>
								<span
									class="cursor"
									style="color: #409eff"
									v-if="scope.$index === resultList.length - 1"
									@click="onAdd"
								>
									增加
								</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-form>
			<p style="margin-left: 20px; color: #606266; font-size: 12px; font-weight: 600">质损描述</p>
			<el-input
				type="textarea"
				v-model="model.damageDesc"
				maxLength="200"
				show-word-limit
				style="width: 600px; margin-bottom: 20px; margin-left: 20px"
				:disabled="disabled"
				:autosize="{ minRows: 4 }"
			/>
			<p class="title" style="margin-left: 20px">验车照片</p>

			<el-form :model="model" :rules="rules" ref="uploadRef" style="margin-left: 20px">
				<el-form-item prop="appearancePicList">
					<p style="margin: 0">
						<span style="color: #f56c6c; margin-right: 4px; line-height: 24px">*</span>
						外观
						<span>({{ model.appearancePicList ? model.appearancePicList.length : 0 }}/25)</span>
					</p>
					<yi-file-uploader
						v-model="model.appearancePicList"
						productType="renrenjia"
						sourceType="image"
						:max="25"
						:uploaderSize="20"
						:disabled="disabled"
						accept=".jpg,.jpeg,.png"
						:allImages="allImages.appearancePicList"
					/>
				</el-form-item>
				<el-form-item prop="interiorPicList">
					<p style="margin: 0">
						<span style="color: #f56c6c; margin-right: 4px; line-height: 24px">*</span>
						内饰
						<span>({{ model.interiorPicList ? model.interiorPicList.length : 0 }}/25)</span>
					</p>
					<yi-file-uploader
						v-model="model.interiorPicList"
						productType="renrenjia"
						sourceType="image"
						:max="25"
						:uploaderSize="20"
						:disabled="disabled"
						accept=".jpg,.jpeg,.png"
						:allImages="allImages.interiorPicList"
					/>
				</el-form-item>
				<el-form-item prop="enginePicList">
					<p style="margin: 0">
						<span style="color: #f56c6c; margin-right: 4px; line-height: 24px">*</span>
						机舱底盘
						<span>({{ model.enginePicList ? model.enginePicList.length : 0 }}/25)</span>
					</p>
					<yi-file-uploader
						v-model="model.enginePicList"
						productType="renrenjia"
						sourceType="image"
						:max="25"
						:uploaderSize="20"
						:disabled="disabled"
						accept=".jpg,.jpeg,.png"
						:allImages="allImages.enginePicList"
					/>
				</el-form-item>
				<el-form-item prop="carInspectPicList">
					<p style="margin: 0">
						其它
						<span>({{ model.carInspectPicList ? model.carInspectPicList.length : 0 }}/25)</span>
					</p>
					<yi-file-uploader
						v-model="model.carInspectPicList"
						productType="renrenjia"
						sourceType="image"
						:max="25"
						:uploaderSize="20"
						:disabled="disabled"
						accept=".jpg,.jpeg,.png"
						:allImages="allImages.carInspectPicList"
					/>
				</el-form-item>
			</el-form>
		</div>

		<template #footer>
			<div class="dialog-footer" v-if="!disabled">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, ref, inject, defineEmits, computed, watch } from 'vue'
import { useState, useModal, useFetch, useSelect } from '@/utils/hooks.js'
import { getStore } from '@/utils/store'
import { status } from '../../enums'
import { form, input } from './config'
import {
	createCarStockIn,
	carSelectList,
	vehicleDetail,
	storageSelect,
	carStockInDetail,
	editCarStockIn,
	getDeliveryByCar,
} from '@/api/vehicle'

const $message = inject('$message')
const emit = defineEmits(['on-refresh', 'on-delivery'])
const formList = ref(form)
const inputForm = ref(input)
const { visible, title, setVisible, setTitle } = useModal()
const { isLoading, fetchData } = useFetch()
const [model, setModel] = useState({})
const [allImages, setAllImages] = useState({})
const [licensePlateOptions, setOptions] = useState([])
const [disabled, setDisabled] = useState(false)
const [carNumDisabled, setCarNumDisabled] = useState(false)
const [resultList, setResultList] = useState([{ dealResult: '', damageAmount: '', key: 0 }])
const [formData, setForm] = useState({ resultList: resultList.value })

const validatorResult = (rule, val, callback) => {
	if (resultList.value.length > 1 && !val) return callback(new Error('请填写处理结果'))
	const { field } = rule
	const index = field.replace(/[^0-9]/gi, '')
	const target = resultList.value[index]
	if (target.damageAmount && !val) {
		callback(new Error('请填写处理结果'))
	} else {
		callback()
	}
}
const validatorAmount = (rule, val, callback) => {
	if (resultList.value.length > 1 && !val) return callback(new Error('请填写质损金额'))
	const { field } = rule
	const index = field.replace(/[^0-9]/gi, '')
	const target = resultList.value[index]

	if (target.dealResult && !val) {
		callback(new Error('请填写质损金额'))
	} else {
		callback()
	}
}

const rules = {
	carMileage: [{ pattern: /^[0-9]*$/, message: '车辆里程只能是整数' }],
	dealResult: [{ validator: validatorResult, trigger: 'blur' }],
	damageAmount: [
		{ validator: validatorAmount, trigger: 'blur' },
		{ pattern: /^[0-9]*$/, message: '质损金额只能是整数' },
	],

	appearancePicList: [{ required: true, message: '请上传外观照片' }],
	interiorPicList: [{ required: true, message: '请上传内饰照片' }],
	enginePicList: [{ required: true, message: '请上传机舱底盘照片' }],
}
const formRef = ref(null)
const inputRef = ref(null)
const resultRef = ref(null)
const uploadRef = ref(null)
const userInfo = getStore({ name: 'userInfo' })

watch(
	() => model.value.inspectResult,
	val => {
		const reason = inputForm.value.find(item => item.prop === 'maintainReason')
		reason.required = !!val
		inputRef.value.clearValidate()
	},
)

const tableLabel = computed(() => {
	if (resultList.value && resultList.value.length) {
		const amount = resultList.value.reduce((acc, cur) => {
			acc += cur.damageAmount * 1 || 0
			return acc
		}, 0)
		return `质损金额（累计：${amount}元）`
	}
	return 0
})

async function onNumChange(val) {
	const { err, data } = await fetchData(vehicleDetail, val)
	if (err) return $message.error(err.message)
	const { id, ...rest } = data
	setModel(Object.assign(model.value, rest, { carId: id }))
	fetchStorage(rest.belongCompanyId)
	fetchDelivery(id)
}
function onDelivery() {
	emit('on-delivery', model.value.deliveryId)
}

async function fetchStorage(companyId) {
	const target = inputForm.value.find(item => item.prop === 'carStorageId')
	target.options = []
	model.value.carStorageId = ''
	const { err, data } = await useSelect(() => storageSelect(20, { companyId, status: 1 }))
	if (err) return console.log('err', err)
	target.options = data
	if (data.length === 1) model.value.carStorageId = data[0].value
}
async function fetchDelivery(id) {
	const { err, data } = await fetchData(getDeliveryByCar, id)
	if (err) return $message.error(err.message)
	console.log('交车单详情', data)
	const { deliveryTime, id: deliveryId } = data || {}
	model.value.deliveryTime = deliveryTime
	model.value.deliveryId = deliveryId
}

function open(id, type) {
	setVisible(true)
	fetchList()
	inputForm.value.map(item => {
		item.attrs.disabled = !!type
	})
	if (id) {
		if (type) {
			fetchDetail(id)
			setTitle('验车入库单详情')
			setVisible(true)
			setCarNumDisabled(true)
			setDisabled(true)
			return
		}
		setDisabled(false)
		fetchDetail(id)
		setCarNumDisabled(true)
		setTitle('编辑验车入库单')
	} else {
		setDisabled(false)
		setTitle('新增验车入库单')
	}
}

function onClose() {
	setVisible(false)
	setModel({})
	setCarNumDisabled(false)
	setResultList([{ dealResult: '', damageAmount: '', key: 0 }])
	setForm({ resultList: resultList.value })
}
function onDelete(scope) {
	const { $index } = scope
	resultList.value.splice($index, 1)
	setResultList(resultList.value)
}
function onAdd() {
	resultList.value.push({ dealResult: '', damageAmount: '', key: resultList.value.length })
	setForm({ resultList: resultList.value })
}
async function fetchDetail(id) {
	const { err, data } = await fetchData(carStockInDetail, id)
	if (err) return $message.error(err.message)

	const {
		list,
		carId,
		companyId,
		carInspectPicList,
		enginePicList,
		interiorPicList,
		appearancePicList,
	} = data
	console.log('data', data)
	const images = {
		carInspectPicList: (carInspectPicList || [])
			.concat(interiorPicList)
			.concat(appearancePicList)
			.concat(enginePicList),
		enginePicList: (enginePicList || [])
			.concat(carInspectPicList)
			.concat(interiorPicList)
			.concat(appearancePicList),
		interiorPicList: (interiorPicList || [])
			.concat(enginePicList)
			.concat(carInspectPicList)
			.concat(appearancePicList),
		appearancePicList: (appearancePicList || [])
			.concat(interiorPicList)
			.concat(enginePicList)
			.concat(carInspectPicList),
	}
	setAllImages(images)
	setResultList(list || [{ dealResult: '', damageAmount: '', key: 0 }])
	setForm({ resultList: list || resultList.value })
	fetchDelivery(carId)
	fetchStorage(companyId)
	setModel(data)
}
async function onSubmit() {
	await Promise.all([
		formRef.value.validate(),
		inputRef.value.validate(),
		resultRef.value.validate(),
		uploadRef.value.validate(),
	])
	const {
		carInspectPicList,
		carMileage,
		carStorageId,
		damageAmount,
		damageDesc,
		carId,
		id,
		inspectResult,
		maintainReason,
		enginePicList,
		appearancePicList,
		interiorPicList,
		createUser,
	} = model.value
	const params = {
		carInspectPicList,
		carMileage,
		carStorageId,
		id,
		createUser: title.value.includes('编辑') ? createUser : userInfo.userId,
		damageAmount,
		damageDesc,
		inspectResult,
		carId,
		maintainReason,
		enginePicList,
		appearancePicList,
		interiorPicList,
	}
	const valid = resultList.value.every(item => item.damageAmount)
	if (valid) params.list = resultList.value
	const service = title.value.includes('编辑') ? editCarStockIn : createCarStockIn
	const { err } = await fetchData(service, params)
	if (err) return $message.error(err.message)
	$message.success('操作成功')
	emit('on-refresh')
	onClose()
}

function mapStatus(value) {
	const target = status.find(item => item.code == value)
	return target ? target.name : '--'
}

async function fetchList() {
	const { err, data } = await fetchData(carSelectList, { statusList: [1, 2, 3, 4, 5, 6, 7, 8] })
	if (err) return $message.error(err.message)
	const options = data.map(item => ({
		...item,
		label: item.licensePlateNum,
		value: item.id,
		disabled: ![7, 6].includes(item.status),
		statusName: mapStatus(item.status),
	}))
	const valid = []
	const invalid = []
	for (const option of options) {
		if ([7, 6].includes(option.status)) valid.push(option)
		else invalid.push(option)
	}
	setOptions(valid.concat(invalid))
	if (title.value.includes('新增')) {
		model.value.createUserName = userInfo.userName
	}
}

defineExpose({ open })
</script>
<style lang="less">
.el-select-v2__placeholder {
	font-weight: 400 !important;
}
.el-select-dropdown__option-item {
	font-size: 14px !important;
}
</style>
<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.result-form {
	.flexible(row, center, space-between);
	margin: 20px 0;
}
.cell {
	height: 100%;
	width: 100%;
	.flexible(column, center, center);
}
.button-list {
	.flexible(row, center, space-between);
}
.cursor {
	cursor: pointer;
}
.custom-option {
	.flexible(row, center, space-between);
}
</style>
